import Loading from './loading'

class ObjectPool {
  // 私有静态属性
  static #ins = null;

  static #loadingInstance = null;

  // 静态 getter 方法
  static get ins() {
    if (!this.#ins) {
      this.#ins = new ObjectPool()
    }

    console.log(this.#ins)

    return this.#ins
  }

  // 获取单例 Loading 实例
  static get loading() {
    if (!this.#loadingInstance) {
      this.#loadingInstance = new Loading()
    }

    // console.log(this.#loadingInstance)

    return this.#loadingInstance
  }
}

export default ObjectPool

// 使用示例
// const { loading } = ObjectPool
// loading.show() // 开始加载
// // 在完成加载后
// loading.hide() // 停止加载
