import axios from 'axios'
// import { Loading } from '@prettyy/ui'
import config from '../../config'
import ObjectPool from './ObjectPool'

const axiosConfig = {
  baseURL: config.serviceUrl,
}

const { loading } = ObjectPool

export class RequestHttp {
  service

  constructor(configs) {
    this.service = axios.create(configs)

    this.service.interceptors.request.use(
      confi => {
        // 开始加载
        loading.show()

        const accessToken = localStorage.getItem('accessToken')
        // eslint-disable-next-line no-param-reassign
        if (accessToken) confi.headers.Authorization = `Bearer ${accessToken}`

        return confi
      },
      error => Promise.reject(error),
    )

    this.service.interceptors.response.use(
      response => {
        // 停止加载
        loading.hide()

        if ([401].includes(response.data.status)) {
          localStorage.removeItem('accessToken')
          window.location.href = '/login'
        }

        if (response.data.status !== 200 && !response.config.responseType) {
          return Promise.reject(response.data.message || response.data.msg)
        }

        return response
      },
      error => {
        /* 删除"系统内部异常"字样 */
        // eslint-disable-next-line no-param-reassign,no-unused-expressions
        error.response.data.message?.includes('系统内部异常：') && (error.response.data.message = error.response.data.message.replace('系统内部异常：', ''))

        return Promise.reject(error)
      },
    )
  }

  post(url, params, _config = {}) {
    return this.service.post(url, params, _config)
  }

  get(url, params, _config = {}) {
    return this.service.get(url, { params, ..._config })
  }

  put(url, params, _config = {}) {
    return this.service.put(url, params, _config)
  }

  downloadGet(url, params = {}, _config = {}) {
    return this.service.get(url, { params, ..._config, responseType: 'blob' })
  }

  downloadPost(url, params, _config = {}) {
    return this.service.post(url, params, { ..._config, responseType: 'blob' })
  }
}

export default new RequestHttp(axiosConfig)
