// 数据处理将childrenList转换为children
export function convertChildrenListToChildren(obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => convertChildrenListToChildren(item))
  } if (obj !== null && typeof obj === 'object') {
    if (obj.childrenList) {
      obj.children = convertChildrenListToChildren(obj.childrenList)
      delete obj.childrenList
      obj.children = obj.children.filter(item => item.type !== 'component')
      if (obj.children.length === 0) {
        delete obj.children
      }
    }

    return obj
  }

  return obj
}

// 递归将将数据中不含有children的数据取出
export function extractWithoutChildren(obj) {
  let result = []
  if (Array.isArray(obj)) {
    obj.forEach(item => {
      result = result.concat(extractWithoutChildren(item))
    })
  } else if (obj !== null && typeof obj === 'object') {
    if (!obj.children) {
      result.push(obj)
    } else {
      result = result.concat(extractWithoutChildren(obj.children))
    }
  }

  return result
}
