const state = {
  buttonAuth: [],
  routerAuth: [],
  menuAuth: [],
}

const mutations = {
  UPDATE_BUTTON_AUTH(states, val) {
    states.buttonAuth = val
  },
  UPDATE_ROUTER_AUTH(states, val) {
    states.routerAuth = val
  },
  UPDATE_MENU_AUTH(states, val) {
    states.menuAuth = val
  },
}

const actions = {
  saveButtonAuth({ commit }, val) {
    commit('UPDATE_BUTTON_AUTH', val)
  },
  saveRouterAuth({ commit }, val) {
    commit('UPDATE_ROUTER_AUTH', val)
  },
  saveMenuAuth({ commit }, val) {
    commit('UPDATE_MENU_AUTH', val)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
