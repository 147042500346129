<script>
export default {
  name: 'AppBarHandBook',
  components: { },
  props: {},
  setup() {
    return {}
  },
}
</script>
<template>
  <a href="https://alidocs.dingtalk.com/i/nodes/4lgGw3P8vP0vBKajTY3bzmBjV5daZ90D?utm_scene=team_space" target="_blank" class="pointer d-flex align-center">
    <v-img
      width="18px"
      src="@/assets/images/handBook@3x.png"
      class="mr12"
    ></v-img>
    <div>操作手册</div>
  </a>
</template>
<style scoped lang='scss'>
a{
  text-decoration: none;
  color: rgba(19, 17, 32, 0.68) !important;
}
</style>
