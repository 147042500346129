import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './app'
import auth from './modules/auth'
import user from './modules/user'
Vue.use(Vuex)
const noPersistence = ['app', 'auth', 'user']

function omitKey(obj, keyToOmit) {
  // eslint-disable-next-line no-unused-vars
  const { [keyToOmit]: _, ...rest } = obj

  return rest
}

export const modules = {
  app,
  auth,
  user,
}

// 持久化插件
function persistencePlugin(config) {
  const { keyName = 'vuex', storage = 'localStorage' } = config

  return function (store) {
    const data = JSON.parse(window[storage].getItem(keyName) || '{}')
    if (JSON.stringify(data) !== '{}') store.replaceState(Object.assign(store.state, data))
    store.subscribe((mutation, state) => {
      let newData = { ...state }
      noPersistence.forEach(key => {
        newData = omitKey(newData, key)
      })
      window[storage].setItem(keyName, JSON.stringify(newData))
    })
  }
}

export default new Vuex.Store({
  plugins: [persistencePlugin({ keyName: 'vuex', storage: 'localStorage' })],
  state: {},
  mutations: {},
  actions: {},
  getters,
  modules: {
    appConfig: appConfigStoreModule,
    ...modules,
  },
})
