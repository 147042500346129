import { render, staticRenderFns } from "./AppBarHandBook.vue?vue&type=template&id=77c06366&scoped=true&"
import script from "./AppBarHandBook.vue?vue&type=script&lang=js&"
export * from "./AppBarHandBook.vue?vue&type=script&lang=js&"
import style0 from "./AppBarHandBook.vue?vue&type=style&index=0&id=77c06366&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c06366",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
