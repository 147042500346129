// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch } from '@vue/composition-api'
import { isFunction } from '@core/utils/is'

// eslint-disable-next-line no-new-func
export const deepCloneFunction = fn => new Function(`return ${fn.toString()}`)()
export function deepCopyWithFunc(obj) {
  if (typeof obj !== 'object') return
  const newObj = obj instanceof Array ? [] : {}
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') newObj[key] = deepCopyWithFunc(obj[key])
      else if (obj[key] instanceof Function) newObj[key] = deepCloneFunction(obj[key])
      else newObj[key] = obj[key]
    }
  }

  return newObj
}

export const isObject = obj => typeof obj === 'object' && obj !== null

export const downloadUrl = 'https://video.vm.xiaowutube.com:8070/downloads/fileDownload'

export const downloadUrlNotWYYT = 'https://video.vm.xiaowutube.com:8070/downloads/fileDownload'

// export const downloadUrl = 'http://192.168.0.147:7081/downloads/fileDownload'

// export const downloadUrlNotWYYT = 'http://192.168.0.147:7081/downloads/fileDownload'

export const isToday = date => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const getToday = () => {
  const date = new Date()
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  month = (month > 9) ? month : (`0${month}`)
  day = (day < 10) ? (`0${day}`) : day

  return `${year}-${month}-${day}`
}

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true
  }

  return false
}

export const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins && ins.$vuetify ? ins.$vuetify : null
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy

  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const isEmpty = value => {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
  // eslint-disable-next-line implicit-arrow-linebreak
  fullName
    .split(' ')
    .map(n => n[0])
    .join('')

// ——— Add Alpha To color ——————— //

export const addAlpha = (color, opacity) => {
  const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityLocal.toString(16).toUpperCase()
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToBottom = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = scrollEl.scrollHeight
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToTop = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = 0
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
  const componentToHex = c => {
    const hex = c.toString(16)

    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  /* eslint-disable indent */
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  /* eslint-enable */
}

// eslint-disable-next-line consistent-return
export function asynchronousReturn(fun) {
  if (isFunction(fun)) {
    return new Promise((resolve, reject) => {
      const data = fun()
      if (data) resolve(data)
      else reject(data)
    })
  }
}

export function getUUID(len = 16) {
  let uuid = ''
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    // eslint-disable-next-line no-bitwise
    const random = (Math.random() * 16) | 0
    if (i === 8 || i === 12 || i === 16 || i === 20) uuid += '-'
    // eslint-disable-next-line no-bitwise,no-nested-ternary
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16)
  }

  return uuid
}

/**
 * 数字千分位
 * @param number
 * @return {string}
 */
export function toThousands(number) {
  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

export function formatNumber(data, headerData) {
  return data.map(item => {
    const processedItem = {}
    Object.assign(processedItem, item)
    headerData.forEach(({ align, value }) => {
      processedItem[value] = align === 'right' && typeof item[value] === 'number' ? toThousands(item[value]) : item[value]
    })

    return processedItem
  })
}

function replaceCommas(value) {
  return value.replace(/,/g, '')
}

function processObject(obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      obj[key] = replaceCommas(obj[key])
    } else if (isObject(obj[key])) {
      processObject(obj[key])
    }
  })
}

function processArray(arr) {
  arr.forEach(item => {
    if (isObject(item)) {
      processObject(item)
    }
  })
}
// 去逗号
export function replaceString(data) {
  if (isObject(data)) {
    processObject(data)
  }
  if (Array.isArray(data)) {
    processArray(data)
  }
}

export const copyText = content => {
  if (navigator.clipboard) {
    // clipboard api 复制
    navigator.clipboard.writeText(content)
  } else {
    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)

    // 隐藏此输入框
    textarea.style.position = 'fixed'
    textarea.style.clip = 'rect(0 0 0 0)'
    textarea.style.top = '10px'

    // 赋值
    textarea.value = content

    // 选中
    textarea.select()

    // 复制
    document.execCommand('copy', true)

    // 移除输入框
    document.body.removeChild(textarea)
  }
}

// 判断字符串是否包含特殊字符
export const containsEmojiAndSpecialChars = str => {
  const specialCharPattern = /[……_]/g

  return specialCharPattern.test(str)
}

// 给参数添加千分位
export const addThousandth = number => {
  if (!number) return ''

  return Number(number).toLocaleString()
}

export const processNumbers = (number, unit = '') => {
  if (!number) return '-'

  return `${unit} ${addThousandth(number)}`
}
// 添加空格
export const addSpace = str => {
  if (!str) return ''
  if (str.length !== 2) return str

  return `${str[0]} ${str[1]}`
}

export function filterMap(arr, val) {
  const data = arr.find(item => item.value === val)

  return (data && data.label) || '-'
}
