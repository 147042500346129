import store from '@/store'

const auth = {
  inserted(el, binding) {
    const { value } = binding
    const { buttonAuth } = store.getters
    if (value === undefined) return
    if (Array.isArray(value) && value.length) {
      const hasPermission = value.some(item => buttonAuth.includes(item))
      if (!hasPermission) el.remove()
    } else if (!buttonAuth.includes(value)) el.remove()
  },
}

export default auth
