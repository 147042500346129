import { getUserInfo } from '@/app/service'

const state = {
  avatar: '', // 头像
  corpId: '',
  mobile: '', // 手机号
  name: '', // 用户名
  appRoleVoList: [], // 权限列表
  title: '', // 职位
  unionId: '',
  userId: '',
  role: [], // 权限列表
  workPlace: '', // 所属区域
}
const mutations = {
  SET_USER_INFO(states, userInfo) {
    states.avatar = userInfo.avatar
    states.corpId = userInfo.corpId
    states.mobile = userInfo.mobile
    states.name = userInfo.name
    states.appRoleVoList = userInfo.appRoleVoList
    states.title = userInfo.title
    states.unionId = userInfo.unionId
    states.userId = userInfo.userId
    states.workPlace = userInfo.workPlace
    states.role = userInfo.role
  },
}

const actions = {
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(response => {
        if (response.data.status === 200) {
          commit('SET_USER_INFO', {
            ...response.data.data,
            role: response.data.data.appRoleVoList.map(val => val.name),
          })

          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
