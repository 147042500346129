const serviceUrlPro = 'https://finance.xiaowutube.com/api'

// const serviceUrlDev = 'http://172.16.25.188:7788' // DD
// const serviceUrlDev = 'http://172.16.25.182:7788' // 新测试本地
// const serviceUrlDev = 'http://172.16.25.184:7788' // xq
// const serviceUrlDev = 'http://172.16.25.69:7788' // xlb

const serviceUrlDev = 'http://172.16.24.200:8072' // 测试
const serviceUrlTest = 'http://172.16.24.200:8072' // 测试

const envServiceUrlMap = {
  development: serviceUrlDev,
  production: serviceUrlPro,
  buildTest: serviceUrlTest,
}
const serviceUrl = envServiceUrlMap[process.env.NODE_ENV]
const corpId = '?corpId=ding90ca1c32ca4e1c31acaaa37764f94726'
const version = '25'

const defaultBucket = process.env.NODE_ENV === 'production' ? 'hzxw-video-mcn' : 'silverdawn-hz-test'

export default {
  serviceUrl,
  loginEndpoint: `${serviceUrl}/sso/doLoginByTicket`,
  userInfoEndpoint: `${serviceUrl}/sso/userInfo`,
  isLoginEndpoint: `${serviceUrl}/sso/isLogin`,
  ssoAuthUrlEndpoint: `${serviceUrl}/sso/getSsoAuthUrl`,
  logoutEndpoint: `${serviceUrl}/sso/logout`,
  routeEndpoint: `${serviceUrl}/base/route`,
  menuEndpoint: `${serviceUrl}/base/menu`,
  tokenType: 'Bearer',
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageVersionKeyName: 'version',
  defaultBucket,
  version,
  corpId,
}
