class Loading {
  constructor() {
    this.isVisible = false
  }

  show() {
    this.isVisible = true
    // console.log('Loading started...')
    // console.log('开启loading')
  }

  hide() {
    this.isVisible = false
    // console.log('Loading stopped...')
    // console.log('关闭loading')
  }
}

export default Loading
