const base = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/dashboards/statistics',
    name: 'dashboard-statistics',
    component: () => import('@/views/dashboards/statistics/Statistics.vue'),
    meta: {
      layout: 'content',
      pageTitle: '首页',
    },
  },
  {
    path: '/dataCenter/ytAccountForm/List',
    name: 'ytaccountForm-list',
    component: () => import('@/views/dataCenter/ytAccountForm/List.vue'),
    meta: {
      layout: 'content',
      pageTitle: 'YT核算报表',
    },
  },
]

export default base
