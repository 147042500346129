import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { computed, getCurrentInstance } from '@vue/composition-api'
import { modules } from '@/store'

/**
 * 获取应用实例
 * @returns {ComponentInstance | undefined}
 */
function getVueInstance() {
  const vm = getCurrentInstance()
  if (vm) return vm.proxy

  throw new Error('helper method only works during setup or Lifecycle Hooks')
}

/**
 * state
 * @param {string} moduleName 模块名称
 * @returns {{}}
 */
export function useState(moduleName) {
  if (!moduleName) throw new Error('moduleName is required')
  const keys = Object.keys(modules[moduleName].state)
  const states = mapState(moduleName, keys)
  const result = {}
  Object.keys(states).forEach(key => {
    result[key] = computed(states[key])
  })

  return result
}

/**
 * getters
 * @returns {{}}
 */
export function useGetters() {
  const vm = getVueInstance()
  if (!vm.$store) throw new Error('useGetters only works during setup or Lifecycle Hooks')
  const getters = mapGetters(Object.keys(vm.$store.getters))
  const result = {}
  Object.keys(getters).forEach(key => {
    result[key] = computed(getters[key])
  })

  return result
}

/**
 * mutations
 * @param { string } moduleName // 模块命名空间
 * @returns {{}}
 */
export function useMutations(moduleName) {
  const vm = getVueInstance()
  const result = {}
  const keys = Object.keys(modules[moduleName].mutations)
  const mutations = mapMutations(moduleName, keys)
  Object.keys(mutations).forEach(key => {
    result[key] = mutations[key].bind(vm)
  })

  return result
}

/**
 * actions
 * @param { string } moduleName // 模块命名空间
 * @returns {{}}
 */
export function useActions(moduleName) {
  const vm = getVueInstance()
  const result = {}
  const keys = Object.keys(modules[moduleName].actions)
  const actions = mapActions(moduleName, keys)
  Object.keys(actions).forEach(key => {
    result[key] = actions[key].bind(vm)
  })

  return result
}

/**
 * 返回当前模块中的所有方法和属性
 * @param moduleName
 * @returns {{}}
 */
export function useStore(moduleName) {
  return {
    ...useState(moduleName),
    ...useMutations(moduleName),
    ...useActions(moduleName),
  }
}
